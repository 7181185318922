import React from 'react';
import './boxipp-react.css';

function Loader(props) {
  return(
    <div className="loader" style={props.styles}></div>
    );
}

function Overlay(props) {
  // visibility logic
  let message = props.win ? "YOU WIN!" : "GAME OVER!";
  //message += "</br>YOU WON " + props.tokens + " COINS!";
  let umesg = props.message;
  let pmesg = "";
  if(props.unique === 1) {
    pmesg = "STARS GAINED: " + props.points;
  }
  if(props.win) {
    return(
      <div className="overlay" style={props.styles}>
        <div className="ngoverlay">
           <div><h2>{message}</h2></div>
           <div>YOU WON {props.tokens} COINS</div>
           <div>{umesg}</div>
           <div>{pmesg}</div>
           <button className="ngoverlay" onClick={props.newGame}>NEW GAME</button>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="overlay" style={props.styles}>
        <div className="ngoverlay">
          <div><h2>{message}</h2></div>
          <button className="ngoverlay" onClick={props.tryAgain}>TRY AGAIN</button>
          <button className="ngoverlay" onClick={props.getFeasible}>BACK TO FEASIBLE</button>
          <button className="ngoverlay" onClick={props.newGame}>NEW GAME</button>
        </div>
      </div>
    );
  }
}

function GameHelp(props) {
  let sn = props.stepNumber;
  // let size = props.size;
  // let rmoves = size*size - sn;
  let pmoves = sn;
  let divs = [];
  let em = 0;

  if(props.extraMovesGame) {
    pmoves = pmoves - props.startFromMove;
    em = props.startFromMove;
  }

  divs.push(<div key="newgame">
      <button onClick={() => props.newGame()}>NEW GAME</button>            
    </div>);
    
    divs.push(<div key="feasible">
            <button key="sol" onClick={() => props.getFeasible()}>BACK TO SOLVABLE</button>
          </div>)

  switch(pmoves) {
    // case 2:
    //   divs.push(<div key="back1">
    //       <button key = "b1" onClick={() => props.movesBack(1)}>BACK 1</button>
    //     </div>);
    //   divs.push(<div key="back2">
    //       <button key = "b2" onClick={() => props.movesBack(2)}>BACK 2</button>
    //     </div>);
    //   break;
    // case 1:
    //   divs.push(<div key="back1">
    //       <button key = "b1" onClick={() => props.movesBack(1)}>BACK 1</button>
    //     </div>);
    //   break;
    case 0:
      break;
    default:
      divs.push(<div key="back1">
          <button key = "b1" onClick={() => props.movesBack(1)}>BACK 1</button>
        </div>);
      // divs.push(<div key="back2">
      //     <button key = "b2" onClick={() => props.movesBack(2)}>BACK 2</button>
      //   </div>);
      // divs.push(<div key="back3">
      //     <button key = "b3" onClick={() => props.movesBack(3)}>BACK 3</button>
      //   </div>);
  }

  divs.push(<div key="help1">
            <button key="1" onClick={() => props.movesAhead(1)}>FORWARD 1</button>
          </div>);
  /*switch(rmoves) {
    case 2:
      divs.push(<div key="help1">
            <button key="1" onClick={() => props.movesAhead(1)}>FORWARD 1</button>
          </div>);
      divs.push(<div key="help2">
            <button key="2" onClick={() => props.movesAhead(2)}>FORWARD 2</button>
          </div>);
      break;
    case 1:
      divs.push(<div key="help1">
            <button key="1" onClick={() => props.movesAhead(1)}>FORWARD 1</button>
          </div>);
      break;
    default:
      divs.push(<div key="help1">
            <button key="1" onClick={() => props.movesAhead(1)}>FORWARD 1</button>
          </div>);
      divs.push(<div key="help2">
            <button key="2" onClick={() => props.movesAhead(2)}>FORWARD 2</button>
          </div>);
      divs.push(<div key="help3">
            <button key="3" onClick={() => props.movesAhead(3)}>FORWARD 3</button>
          </div>);

  }*/

  

  if(em === 20) {
    return (
      <div key="newgame">
        <button onClick={() => props.newGame()}>NEW GAME</button>            
      </div> 
    );
  }
  else { 
    return divs;
  }
}

function CoordSquare(props) {
  return (
    <button className="board-coord">
      {props.label}
    </button>
  );
}

class Square extends React.Component {

  render() {
    const styles = {};
    if(this.props.moves.includes(this.props.mykey)) { 
      styles.backgroundColor = '#1BA555';
      //console.log("playedmove: "+props.playedmove+"\nlength: "+props.moves.length+"\nmykey: "+props.mykey);
      if(this.props.playedmove === this.props.mykey && this.props.moves.length > 1) {
        styles.backgroundColor = '#F92D49';
      }
    }
    else if(this.props.extraMovesGame && this.props.value <= this.props.startFromMove && this.props.value !== 0) {
      styles.backgroundColor = '#C4AEFF';
    }
    let label = this.props.value;
    if(this.props.extraMovesGame) {
      //console.log("value: " + props.value+"\nSTFMV: "+props.startFromMove);
      if(this.props.value < this.props.startFromMove && this.props.value !== 0) {
        label = '';
      }
    }
    return (
      <button className={(label === '*') ? "filledsquare" : "square"} onClick={this.props.onClick} style={styles}>
        {(this.props.value === 0) ? '' : label}
      </button>
    );
  }
}

class Board extends React.Component {
  renderSquare(x, y) {
    var mykey = (x+1).toString() + "-" + (y+1).toString();
    return (
      <Square
        key={mykey}
        mykey={mykey}
        value={this.props.squares[x][y]}
        onClick={() => this.props.onClick(x, y)}
        x={x}
        y={y}
        moves={this.props.nextmoves}
        playedmove={this.props.playedmove}
        extraMovesGame={this.props.extraMovesGame}
        startFromMove={this.props.startFromMove}
      />
    );
  }
  
  renderCoordSqare(label, direction) {
    let lb = (label === '' ? "empty" : label);
    var mykey = lb.toString() + "^" + direction;
    return (
      <CoordSquare
        key={mykey}
        label={label.toString()}
      />
    );
  }
  
  renderBoard(size) {
    // var coordrow = [];
    // const empty = '';
    // coordrow.push(this.renderCoordSqare(empty, "corner"));
    // for (var i = 1; i < size+1; i++) {
    //     coordrow.push(this.renderCoordSqare(i, "col"));
    // }
    var divs = [];
    // divs.push(<div key="coordrow" className="board-row">{coordrow}</div>);
    for(let i=0; i < size; i++) {
      var row = [];
      // row.push(this.renderCoordSqare(i+1, "row"));
      for(let j=0; j < size; j++) {
        row.push(this.renderSquare(i, j));
      }
      divs.push(<div key={i} className="board-row">{row}</div>);
    }
    return divs;
  }

  render() {
    var size = this.props.size;
    return (
      <div>
        {this.renderBoard(size)}
      </div>
    );
  }
}

class Game extends React.Component {
  constructor(props) {
    super(props);
    let msize = this.props.size;
    let id = this.props.userID;
    let activeUser = this.props.activeUser;
    this.resetPM = this.resetPM.bind(this)
    this.state = {
      history: [
        {
          squares: Array(msize).fill(0).map(row => new Array(msize).fill(0)),
          nextPossibleMoves: [],
          playedmove: ""
        }
      ],
      size: msize,
      activeUser: activeUser,
      stepNumber: 0,
      timer: 0,
      timerOn: false,
      moves: [],
      data: [],
      tokens: 0,
      win: false,
      gameover: false,
      gameInit:false,
      solstr: "",
      uid: id,
      alltokens: 0,
      extraMovesGame: false,
      subscription: 0,
      points: 0,
      allpoints: 0,
      unique: 0,
      finalmessage: "",
      loading: false,
      pm: "",
      helpmessage: ""
    };
  }

  resetPM() {
    this.setState({
      pm: "",
    });
  }

  getMovesStr() {
    const moves = this.state.moves;
    let movstr = "";
    for(let i=0; i < moves.length; i++) {
      if(i < moves.length - 1) {
        movstr += moves[i] + "^";
      } 
      else {
        movstr += moves[i];
      }
    }
    return movstr;
  }

  getMovesStrArg(moves) {
    let movstr = "";
    for(let i=0; i < moves.length; i++) {
      if(i < moves.length - 1) {
        movstr += moves[i] + "^";
      } 
      else {
        movstr += moves[i];
      }
    }
    return movstr;
  }

  async getSubStatus() {
    const id = this.state.uid;
    try {
      await this.setState({
        loading: true
      }); 
      await fetch('https://boxipp.com/data/api.php?action=subscription&uid=' + id.toString())
      .then(response => response.json())
      .then(data => this.setState({ 
        subscription: parseInt(data.subscription),
        loading:false
        }));
      // await fetch('https://boxipp.com/data/api.php?action=subscription&uid=' + id.toString())
      // .then(response => response.json())
      // .then(data => this.setState({ subscription: parseInt(data.subscription)}));
    }
    catch (error) {
      console.log(error);
    }
  }

  async getMessage(moves) {
    const scenario = this.getMovesStrArg(moves);
    let sm = 0;
    if(this.state.extraMovesGame) {
      sm = this.props.startFromMove;
    }
    let moves_count = this.state.history[this.state.stepNumber].nextPossibleMoves.length;
    const human_moves = this.state.stepNumber-sm;
    //console.log("HUMU="+human_moves+" SCE="+scenario);
    let str = "https://boxipp.com/data/api.php?action=umessage&scenario="
              + scenario
              + "&moves="
              + human_moves.toString()
              + "&moves_count="
              + moves_count.toString()
              + "&size=" 
              + this.props.size.toString()
              + "&prefilled="
              + sm.toString();
    //console.log(str);
    let message = "";
    try {
      await this.setState({
        loading: true
      }); 
      await fetch(str)
      .then(response => response.json())
      .then(data => {
        message = data.message;
        this.setState({
          helpmessage: message,
          loading: false
        });
      });
      // return message;
    }
    catch (error) {
      console.log(error);
    }
  }

  async getUnique(moves) {
    const scenario = this.getMovesStrArg(moves);
    let sm = 0;
    if(this.state.extraMovesGame) {
      sm = this.props.startFromMove;
    }
    let str = "https://boxipp.com/data/api.php?action=solution&scenario="
              + scenario
              + "&uid="
              + this.state.uid.toString()
              + "&size="
              + this.state.size.toString()
              + "&prefilled=" 
              + sm.toString();
    // let unique = 0;
    let points = 0;
    // let message = "";
    try {
      await this.setState({
        loading: true
      }); 
      await fetch(str)
      .then(response => response.json())
      .then(data => {
        points = data.points;
        this.setState({
          unique: parseInt(data.unique),
          points: parseInt(data.points),
          finalmessage: data.message,
          loading: false
        });
      });
      return points;
    }
    catch (error) {
      console.log(error);
    }
  }

  async getFeasible() {
    if(this.state.alltokens + this.state.tokens < 50) {
      alert("Not enough Coins!\nYou need 50");
      return;
    }

    const gameover = this.state.gameover;
    if(gameover) {
      let tok = this.state.tokens;
      this.setState({
        gameover: false,
        gameInit: true,
        tokens: 0,
        timer:0,
        timerOn: false,
      }, () => {
        this.stopTimer();
        if(this.props.activeUser !== 0) {
          this.fetchTokens();
          this.fetchPoints();
        }
        else {
          this.setState({
            tokens: tok
          });
        }
      });
    }

    const scenario = this.getMovesStr();
    // console.log("SCENARIO " +scenario)
    let str = "https://boxipp.com/data/api.php?action=feasible&scenario=" + scenario;
    let sol = "";
    try {
      await this.setState({
        loading: true
      }); 
      await fetch(str)
      .then(response => response.json())
      .then(data => {
        sol = data.moves;
        this.setState({loading:false});
      });
    }
    catch (error) {
      console.log(error);
    }
    // console.log("SOL: " + sol);
    let move = sol.split('^').length;

    // console.log("FEASI: " + move);
    // console.log("state snm: " + this.state.stepNumber);

    if(move < this.state.stepNumber) {
      const tokens = this.state.tokens - 50;
      this.setState({
        tokens: tokens,
      }, () => {
        this.jumpTo(move);
      });
    }
    else {
      alert("You are already on a feasible scenario!");
    }
    
  }

  async fetchMoves(props) {
    const sm = this.props.startFromMove;
    if(sm === 10 || sm === 15 || sm === 20) {
      try {
        await this.setState({
          loading: true
        }); 
        await fetch('https://boxipp.com/data/api.php?action=randsolution')
          .then(response => response.json())
          .then(data => this.setState({ 
            solstr: data.solution,
            moves: data.solution.split('^', sm),
            loading: false
          }));
      } 
      catch (error) {
        console.error(error);
      }
      //console.log("FETCH"+this.state.moves + " SOLSTR"+ this.state.solstr)
      this.setState({extraMovesGame: true});
      this.playMoves(this.state.solstr, sm);
    }
  }

  async fetchPoints() {
    try {
      await this.setState({
        loading: true
      }); 
      await fetch("https://boxipp.com/data/api.php?action=points&uid=" + this.state.uid)
        .then(response => response.json())
        .then(data => this.setState({ 
          allpoints: parseInt(data.points),
          loading: false
        }));
    } 
    catch (error) {
      console.error(error);
    }
  }

  async fetchTokens(props) {
    // get user tokens
    try {
      await this.setState({
        loading: true
      }); 
      await fetch("https://boxipp.com/data/api.php?action=tokens&uid=" + this.state.uid)
        .then(response => response.json())
        .then(data => this.setState({ 
          alltokens: parseInt(data.tokens),
          loading: false
        }));
    } 
    catch (error) {
      console.log("CANT FETCH")
      console.error(error);
    }
  }

  async postTokens(props) {
    let poststr = "https://boxipp.com/data/api.php?action=utokens&uid=" +
                    this.state.uid + 
                    "&tokens=" + 
                    this.state.tokens;
    try {
      await this.setState({
        loading: true
      }); 
      await fetch(poststr)
        .then(response => response.json())
        .then(data => {
          console.log("Added " + data.utokens + " tokens to UID " + this.state.uid);
          this.setState({
            loading: false
          });
          });
    }
    catch (error) {
      console.error(error);
    }
  }

  async movesAhead(number) {
    if(this.state.alltokens + this.state.tokens < number*10) {
      alert("Not enough Coins!\nYou need " + number*10);
      return;
    }

    // const moves = this.state.moves;
    let movstr = this.getMovesStr();
    console.log("MOVESTR: "+movstr);

    let str = "https://boxipp.com/data/api.php?action=movesahead&scenario=" +
              movstr + "&moves=" + number;

    // start timer if its off
    if(!this.state.timerOn) {
      this.setState({timerOn: true});
      this.startTimer();
    }

    
    var movesahead = "";
    try {
      await this.setState({
        loading: true
      }); 
      await fetch(str)
        .then(response => response.json())
        .then(data => {
          this.setState({loading:false});
          movesahead = data.moves; 
          if(movesahead === "") {
            alert("There are no feasible moves ahead");
            // do nothing
          }
          else {
            const tokens = this.state.tokens - (10*number);
            this.setState({
              tokens: tokens,
            }, this.playMoves(movesahead, number));
            
          }
        });
    }
    catch (error) {
      console.log(error);
    }
    
    console.log("Moves ahead: " + movesahead);
    if(movesahead === "") {
      return;
    }

    

    // TODO an einai zero, den exi lysi
  }

  movesBack(number) {
    if(this.state.alltokens + this.state.tokens < number*10) {
      alert("Not enough Coins!\nYou need " + number*10);
      return;
    }
    const tokens = this.state.tokens - (10*number);
    this.setState({
      tokens: tokens,
    }, () => {
      // this.fetchTokens();
      this.jumpTo(this.state.stepNumber-number);
    });
    
  }

  componentDidMount(props) {
    if(this.props.activeUser !== 0) {
      this.getSubStatus();
      this.fetchTokens();
      this.fetchPoints();
    }
    else {
      this.setState({
        tokens: 100,
      });
    }
    
    if(this.props.startFromMove !== 0) {
      this.fetchMoves();
    }
    else {
      this.getMessage(this.state.moves);
    }

  }
  
  tick() {
    let oldTime = this.state.timer;
    this.setState({
      timer: oldTime+1, 
    });
  }
  
  startTimer() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }
  
  stopTimer() {
    clearInterval(this.timerID);
  }
  
  jumpTo(step) {
    // console.log("PM: "+this.state.history[step+1].playedmove);
    this.setState({
      pm: this.state.history[step+1].playedmove,
      stepNumber: step,
      history: this.state.history.slice(0, step + 1),
      moves: this.state.moves.slice(0, step),
      
      // TODO FIX
    }, () => {
      this.getMessage(this.state.moves);
    });
  }

  // sleep(milliseconds) {
  //   const date = Date.now();
  //   let currentDate = null;
  //   do {
  //     currentDate = Date.now();
  //   } while (currentDate - date < milliseconds);
  // }

  newGame() {
    
    
    //Refresh window
    window.location.reload();

    
     //     let sm = this.props.startFromMove;
//     let em = false;
//     if(sm === 10 || sm === 15 || sm === 20) {
//       em = true;
//     }
// 
//     this.stopTimer();
// 
//     this.setState({
//       history: this.state.history.slice(0,1),
//       moves: this.state.moves.slice(0,1),
//       timer: 0,
//       timerOn: false,
//       tokens: 0,
//       win: false,
//       gameover: false,
//       solstr:"",
//       extraMovesGame: em,
//       gameInit: true,
//       stepNumber: 0,
//       pm: "",
//     }, () => {
// 
//       if(this.props.activeUser !== 0) {
//         this.fetchTokens();
//         this.fetchPoints();
//       }
// 
//       if(this.state.extraMovesGame) {
//         this.fetchMoves();
//       }
// 
//       this.getMessage(this.state.moves);
//     });


    
  }

  tryAgain() {
    let sm = this.props.startFromMove;
    let tok = this.state.tokens;
    this.setState({
      win:false,
      gameover:false,
      tokens: 0,
      timer:0,
      timerOn: false,
      gameInit:true,
      pm:""
    }, () => {
      this.stopTimer();
      if(this.props.activeUser !== 0) {
        this.fetchTokens();
        this.fetchPoints();
      }
      else {
        this.setState({
          tokens: tok
        });
      }
      this.jumpTo(sm);
    });
  }

  playMoves(movesStr, sm) {
    
    if(movesStr === "") {
      return;
    }
    
    var x, y;
    const moves = movesStr.split("^");
    for(let move = 0; move < sm; move++) {
      [x, y] = moves[move].split("-");
      x = parseInt(x)-1;
      y = parseInt(y)-1;
      this.playMove(x, y, this.state.stepNumber);
    }
    this.setState({gameInit: true})
  
    
  }

  async playMove(x, y, step) {
    const history = this.state.history.slice(0, this.state.stepNumber + 1);
    const current = history[history.length - 1];
    const squares = deepCopy(current.squares);
    const moves = this.state.moves.slice(0, this.state.stepNumber);
    let win = false;
    var mykey = (x+1).toString() + "-" + (y+1).toString();
    var tokens = this.state.tokens;

    // Play the move
    moves.push(mykey);
    squares[x][y] = step+1;

    // Get all Possible moves
    var pm = this.props.possMoves;
    var npm = pm.find(o => o.name === mykey)['value'];
    
    // Filter only Legal moves
    var finalmoves = []
    for (let move in npm) {
      var [xi, yi] = npm[move].split('-');
      xi = parseInt(xi);
      yi = parseInt(yi);
      if(squares[xi-1][yi-1] === 0) {
        finalmoves.push(npm[move]);
      }
    }

    // Check for end condition
    if ((history.length === (this.props.size * this.props.size)) && finalmoves.length === 0) {
      if(this.props.activeUser !== 0) {
        await this.getUnique(moves).then(result => {
          this.setState({
            tokens: tokens + parseInt(result)
          })
        }).catch(err => {
          // got error
        });
      }
      
      
      this.setState({timerOn:false});
      this.stopTimer();
      win = true;
      // this.getUnique();
      //post tokens
      //alert("You Win!");
    }

    this.setState({
      history: history.concat([
        {
          squares: squares,
          nextPossibleMoves: finalmoves,
          playedmove: mykey,
        }
      ]),
      stepNumber: history.length,
      moves: moves,
      win: win,
    }, () => {
      // console.log("SN:"+this.state.stepNumber+"\tSM:"+this.props.startFromMove);
      if(this.state.stepNumber === this.props.startFromMove) {
        this.getMessage(this.state.moves);
      }
      if(win) {
        if(this.props.activeUser !== 0) {
          this.postTokens();
        }
      }
    });
    this.resetPM();
  }

  async handleClick(x, y) {
    const history = this.state.history.slice(0, this.state.stepNumber + 1);
    const current = history[history.length - 1];
    const squares = deepCopy(current.squares);
    const nextMoves = current.nextPossibleMoves.slice();
    const moves = this.state.moves.slice(0, this.state.stepNumber);
    let win = false;
    let gameover = false;
    let tokensFromMove = 10;
    let em = this.state.extraMovesGame;
    let sm = this.props.startFromMove;

    if((sm === 10 || sm === 15 || sm === 20) && em) {
      // do nothing
    } 
    else {
      sm = 0;
    }
    if (isFull(squares, this.props.size)) {
      return;
    }
    if (squares[x][y] !== 0) {
      return;
    }
    
    var mykey = (x+1).toString() + "-" + (y+1).toString();
    //console.log(mykey);
    
    if (((nextMoves.length === 0) && (this.state.stepNumber === 0)) || this.state.gameInit) {
      // If it's the first move
      if(!this.state.gameInit) {
        squares[x][y] = this.state.stepNumber + 1;
      }
      // if the game comes with starting moves
      else {
        if(nextMoves.includes(mykey) || ((nextMoves.length === 0) && (this.state.stepNumber === 0))) {
          squares[x][y] = this.state.stepNumber + 1;
        }
        else {
          console.log("Weird illegal move");
          return;
        }

      }
      moves.push(mykey);
      if(!this.state.timerOn) {
        this.setState({timerOn: true});
        this.startTimer();
      }
      this.setState({gameInit: false})
    }
    else if (nextMoves.includes(mykey)) {
      // If the move is legal
      squares[x][y] = this.state.stepNumber + 1;
      moves.push(mykey);
    }
    else if ((nextMoves.length === 0) && (this.state.stepNumber !== 0)) {
      // If there are no moves
      console.log("Game Over");
      // maybe reset?
      // this.jumpTo(0);
      return;
    }
    else {
      // Illegal Move
      console.log("Illegal move");
      return;
    }
    
    // Get all Possible moves
    var pm = this.props.possMoves;
    var npm = pm.find(o => o.name === mykey)['value'];
    
    // Filter only Legal moves
    var finalmoves = []
    for (let move in npm) {
      var [xi, yi] = npm[move].split('-');
      xi = parseInt(xi);
      yi = parseInt(yi);
      if(squares[xi-1][yi-1] === 0) {
        finalmoves.push(npm[move]);
      }
    }

    // Check for end condition
    if ((history.length === (this.props.size * this.props.size)) && finalmoves.length === 0) {
      if(this.props.activeUser !== 0) {
        await this.getUnique(moves).then(result => {
          this.setState({
            tokens: this.state.tokens + parseInt(result)
          })
        }).catch(err => {
          // got error
        });
      }
      this.setState({timerOn:false});
      this.stopTimer();
      win = true;
    }
    else if (finalmoves.length === 0) {
      this.setState({timerOn:false});
      this.stopTimer();
      gameover = true;
      //alert("Game Over!");
    }

    let token = (history.length > tokensFromMove + sm) ? 1 : 0;
    let newtokens = this.state.tokens+token;

    // Set the state for the next move
    this.setState({
      history: history.concat([
        {
          squares: squares,
          nextPossibleMoves: finalmoves,
          playedmove: mykey,
        }
      ]),
      stepNumber: history.length,
      moves: moves,
      tokens: newtokens,
      win: win,
      gameover: gameover,
    }, () => {
      this.getMessage(this.state.moves);
      if(win||gameover) {
        if(this.props.activeUser !== 0) {
          this.postTokens();
        }
      }
    });
    this.resetPM();
  }

  render() {
    const history = this.state.history;
    const current = history[this.state.stepNumber];
    const playedmove = this.state.pm;
    const win = this.state.win;
    const olstyles = {}
    const gameover = this.state.gameover;
    const sm = this.props.startFromMove;
    const tokens = this.state.tokens;
    const fmesg = this.state.finalmessage;
    const unique = this.state.unique;
    const points = this.state.points;
    const loadstyles = {};
    const loading = this.state.loading;

    if(win || gameover) {
      olstyles.display = "flex";
    }
    else {
      olstyles.display = "none";
    }

    if(loading) {
      loadstyles.display = "flex";
    }
    else {
      loadstyles.display = "none";
    }

    const moves = history.map((step, move) => {
      const desc = move ?
        //'Go to move #' + move + ' (' + this.state.moves[move-1] + ')' :
        //'Go to game start';
         move :
        '0';
        //'Go to game start';
      if(move >= sm || this.state.solstr === "") {
        return (
          <li key={move} className="history-move-active">
            <button onClick={() => this.jumpTo(move)}>{desc}</button>
          </li>
        );
      }
      else {
        // return (<div> </div>);
        return (
          <li key={move} className="history-move-inactive">
            <div>{desc}</div>
          </li>
        );
      }
    });

    const nextmoves = current.nextPossibleMoves.slice();
    const size = this.props.size;
    //const buybutton = <button className="buyButton">Buy More Coins</button>
    const buybutton = <button className="buyButton" onClick={event =>  window.location.href="https://boxipp.com/product-category/boxipp-coins/"}>Buy More Coins</button>
    //const subbutton = <button className="subButton">Subscribe</button>
    const subbutton = <div id="subbutton"><h3>Buy a Game History Subscription to unlock free moves forward and back.</h3><a href="https://boxipp.com/product/boxipp-bronze-member/">Subscribe</a></div>

return (
      <div className="game" id="game">
        <div>
          <Loader
            styles={loadstyles}
          />
        </div>
        <div>
          <Overlay
            styles={olstyles}
            win={win}
            newGame={() => this.newGame()}
            tryAgain={() => this.tryAgain()}
            tokens={tokens}
            message={fmesg}
            unique={unique}
            points={points}
            getFeasible={() => this.getFeasible()}
          />
        </div>
        <div className="game-messages" id="game-messages">
          <div id="help-message">
            {this.state.helpmessage}
          </div>
        </div>
        
        <div className="game-info" id="game-info">
         <div id="score-block">
          <div id="game-move">MOVE: {this.state.stepNumber}</div>
          
          <div id="current-tokens">GAME COINS: {this.state.alltokens + this.state.tokens} {(this.state.alltokens+this.state.tokens === 0) ? buybutton : null}</div>
          </div>
          <GameHelp 
            newGame={() => this.newGame()}
            movesAhead={(num) => this.movesAhead(num)}
            movesBack={(num) => this.movesBack(num)}
            stepNumber={this.state.stepNumber}
            size={this.state.size}
            extraMovesGame={this.state.extraMovesGame}
            startFromMove={this.props.startFromMove}
            getFeasible={() => this.getFeasible()}
          />
          {/*<div>UID: {this.state.uid}</div>
          <div>User Score: {this.state.allpoints}</div>
          
          <div>User Tokens: {this.state.alltokens}</div>*/}
         
          {/*<div>Time: {new Date(this.state.timer * 1000).toISOString().substr(11, 8)}</div>*/}
          
          
        </div>
        <div className="game-board">
          <Board
            squares={current.squares}
            onClick={(x, y) => this.handleClick(x, y)}
            nextmoves={nextmoves}
            playedmove={playedmove}
            size={size}
            extraMovesGame={this.state.extraMovesGame}
            startFromMove={this.props.startFromMove}
          />
        </div>
        <div id="game-history">
        <div id="history-block">
          <div id="history-title">Game History</div>
          </div>
        	<ul>{(this.state.subscription !== 0) ? moves : subbutton}</ul>
        </div>
      </div>
    );
  }
}

export default Game;

// ========= Board Size ===================

//const bsize = 5;

// ========= Helper Functions =============




// Checks if a certain value exists
// on the board
const hasValue = (board, value, bsize) => {
  for (let i=0; i < bsize; i++) {
    for (let j=0; j < bsize; j++) {
      if (board[i][j] === value) {
        return true;
      }
    }
  }
};

// Checks if the board is full
const isFull = (board, bsize) => {
  return !hasValue(board, 0, bsize);
};

// Required to copy state on the
// 2D board array
const deepCopy = (arr) => {
  let copy = [];
  arr.forEach(elem => {
    if(Array.isArray(elem)){
      copy.push(deepCopy(elem));
    }else{
      copy.push(elem);
    }
  })
  return copy;
}
