import React from 'react';
import ReactDOM from 'react-dom';
import Game from './boxipp'
import possMoves from './possmoves'





// ================= Render ================
// ReactDOM.render(<App/>, document.getElementById("boxipp-react"));
ReactDOM.render(<Game 
									activeUser={window.activeUser}
									size={window.boxippSize} 
									possMoves={possMoves(window.boxippSize)}
									startFromMove={window.startFromMove}
									userID={window.userID}
									/>, document.getElementById("boxipp-react"));
// ReactDOM.render(<Boxipp/>, document.getElementById("boxipp-react"));

// =========================================



