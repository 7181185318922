const possMoves = (bsize) => {
  var moves = [];
  for (let i=1; i<=bsize; i++) {
    for (let j=1; j<=bsize; j++) {
      let values = []
      let name = (i).toString() + "-" + (j).toString();
      if(j-3 > 0) {
        values.push((i).toString() + "-" + (j-3).toString());
      }
      if(j+3 <= bsize) {
        values.push((i).toString() + "-" + (j+3).toString());
      }
      if(i-3 > 0) {
        values.push((i-3).toString() + "-" + (j).toString());
      }
      if(i+3 <= bsize) {
        values.push((i+3).toString() + "-" + (j).toString());
      }
      if(i+2 <= bsize && j-2 > 0) {
        values.push((i+2).toString() + "-" + (j-2).toString());
      }
      if(i+2 <= bsize && j+2 <= bsize) {
        values.push((i+2).toString() + "-" + (j+2).toString());
      }
      if(i-2 > 0 && j-2 > 0) {
        values.push((i-2).toString() + "-" + (j-2).toString());
      }
      if(i-2 > 0 && j+2 <= bsize) {
        values.push((i-2).toString() + "-" + (j+2).toString());
      }
      moves.push({
        name:   name,
        value:  values
      });
    }
  }
  return moves;
}

export default possMoves;